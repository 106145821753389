import React, { ReactNode, ReactElement } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import { Theme, CssBaseline, PaletteType } from '@material-ui/core'
import { getItem } from 'libs/localStorageWrapper'
import * as Sentry from '@sentry/react'
import themes from 'theme'
import { SnackbarProvider } from 'notistack'

const wrapRootElement = ({ element }: { element: ReactNode }): ReactElement => {
  function getTheme(): Theme {
    const theme = getItem('theme', 'light' as PaletteType)

    return themes[theme]
  }

  return (
    <Sentry.ErrorBoundary fallback={'An error has occured'}>
      <ThemeProvider theme={getTheme()}>
        <SnackbarProvider maxSnack={3} preventDuplicate>
          <CssBaseline />
          {element}
        </SnackbarProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  )
}

export default wrapRootElement
