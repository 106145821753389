import * as Sentry from '@sentry/react'
import { consentGiven } from './cookieConsentUtility'
// Prevent build error "window is undefined" but keep working
const isServer = typeof window === 'undefined'

export const getItem = <TValue>(key: string, initialValue: TValue): TValue => {
  if (isServer || !consentGiven()) {
    return initialValue
  }

  try {
    const item = window.localStorage.getItem(key)
    return item ? JSON.parse(item) : initialValue
  } catch (error) {
    Sentry.captureException(error)
    return initialValue
  }
}

export const setItem = <TValue>(key: string, value: TValue): void => {
  try {
    if (!isServer && consentGiven())
      window.localStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    Sentry.captureException(error)
  }
}
