/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export { default as wrapRootElement } from './src/libs/wrapRootElement'

export const onServiceWorkerUpdateReady = () => {
  window.dispatchEvent(new CustomEvent('sworkerUpdate'))
}
